@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

body {
  background-color: rgb(30 27 75) !important;
}
h2 {
    font-size: 25px;
    line-height: 30px;
    color: #fff;
}
.btn-primary {
  background-color: rgb(107 33 168);
  color: #fff;
  width: 100%;
  transition: 0.3s;
}
.btn-primary:hover {
    background-color: rgba(107, 33, 168, 0.75);
  }
form {
    max-width: 400px;
    margin: 0 auto;
    background-color: rgba(167, 139, 250, 0.092);
}
.offers {
    max-width: 600px;
    margin: 30px auto 0;
}
select {
    margin-top: 15px;
    background-color: rgb(196 181 253);
    width: 100%;
    font-size: 22px;
    appearance: none;
    cursor: pointer;
}
.descriptionText {
    color: #fff;
    font-size: 16px;
    line-height: 25px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    border-color: rgba(255, 255, 255, 0.181);
}
.offer img {
    max-width: 150px;
    width: fit-content;
}
.offer p {
    color: #fff;
    font-size: 20px;
    line-height: 25px;
}
.selectdiv select {
    background-image: url(../public/images/caret-down.png);
    background-repeat: no-repeat;
    background-position: right 15px top 13px;
}
.green {
	color: rgb(163 230 53);
}
.red {
    color: rgb(225 29 72);
}
.offer {
    max-width: 175px;
}
@media(min-width: 320px) and (max-width: 767px) {
    #root {
        padding: 15px;
    }
    .offers {
        justify-content: center;
    }
    .descriptionText {
        font-size: 14px;
        line-height: 23px;
    }
    .offers .offer {
        margin-bottom: 25px;
        max-width: 33%;
    }
    .offer img {
        max-width: 90%;
    }
    .offer p {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        line-height: 23px;
    }
    form {
        margin-top: 70px;
    }
}
@media(min-width: 1260px) and (max-width: 10000px) {
    body {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }   
    body #root {
        min-width: 700px;
    }
}